<template>
  <div>
    <v-row>
      <v-col cols="12">
        <select-warehouse-model
          v-model="id_warehouse"
          :label="$t('labels.warehouse')"
          dense
          outlined
          clearable
          hide-details
          @change="focusBasketInput"
        ></select-warehouse-model>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model.number="basket_code"
          ref="basketCodeRef"
          class="c-input-small"
          :label="$t('labels.scan_basket_code')"
          :disabled="!id_warehouse"
          dense
          outlined
          clearable
          hide-details
          @keyup.enter="getReceiptBasket"
          append-icon="mdi-qrcode-scan"
          @click:append="showQRCodeScan('basket_code')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="success"
          block
          :disabled="isDisabledBtn"
          @click="createCommand"
          >{{ $t("labels.confirm") }}</v-btn
        >
      </v-col>

      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">#</th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.sku") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.basket") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) in scanned_baskets"
                :key="`se_${item.id}`"
                class="text-center"
              >
                <td>{{ key + 1 }}</td>
                <td>{{ item.goods_sku }}</td>
                <td>{{ item.basket_code }}</td>
              </tr>
              <tr
                v-for="(item, key) in scanning_baskets"
                :key="`si_${item.id}`"
                class="text-center error--text"
              >
                <td>{{ key + 1 + scanned_baskets.length }}</td>
                <td>{{ item.goods_sku }}</td>
                <td>{{ item.basket_code }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12" v-if="waiting_baskets.length > 0" class="mt-10">
        <v-simple-table class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.basket") }}
                  <span class="error--text"
                    >({{ waiting_baskets.length }})</span
                  >
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.receipt_time") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.position") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.quantity_1") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.employee_receipt_1") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.employee_stowing") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in waiting_baskets"
                :key="item.id"
                class="text-center"
              >
                <td>
                  <span class="error--text">{{ item.basket_barcode }}</span>
                  <br />
                  <span class="primary--text">{{ item.goods_sku }}</span> <br />
                  {{ $t(`labels.waiting_stowing_status_${item.status}`) }}
                </td>
                <td>{{ formatDateTime(item.created_at) }}</td>
                <td>
                  <div v-html="getStorageLocation(item.storage_location)"></div>
                </td>
                <td>
                  <div class="font-weight-medium fs-16 success--text">
                    {{ item.net_quantity - item.count_failed }}
                  </div>
                  <div v-if="item.count_failed > 0" class="font-italic fs-12">
                    ( QC:
                    {{ item.net_quantity }} -
                    <span class="error--text">
                      {{ item.count_failed }}
                    </span>
                    )
                  </div>
                </td>
                <td>
                  {{
                    item.employee_last_handle_name ||
                    item.employee_last_handle_full_name
                  }}
                  <br />
                  <span class="grey--text">
                    {{ item.history_tracking_id }}
                  </span>
                </td>
                <td>
                  {{
                    item.employee_stowing_name ||
                    item.employee_stowing_full_name
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { formatDateTime } from "@/libs/helpers";

export default {
  name: "GoodsReturnReceiveBasket",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  data: () => ({
    basket_code: null,
    isLoading: false,
    warehouses: [],
    id_warehouse: null,
    waiting_baskets: [],
    scanned_baskets: [],
    scanning_baskets: [],
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    isDisabledBtn() {
      return (
        this.scanned_baskets.length === 0 || this.scanning_baskets.length > 0
      );
    },
  },
  methods: {
    formatDateTime,
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.getReceiptBasket();
    },
    getStorageLocation(locations) {
      if (!locations) {
        return "";
      }
      const locationSplit = locations.split(";");
      return locationSplit.join("<br>");
    },
    async getReceiptBasket() {
      if (!this.basket_code) {
        return false;
      }

      const checkBasket = this.scanned_baskets.find(
        (c) => c.basket_code == this.basket_code
      );
      if (checkBasket) {
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(
          this.$t("messages.scanned_basket_code", { code: this.basket_code })
        );
        this.basket_code = null;
        return false;
      }

      if (this.scanning_baskets.length > 0) {
        const checkScanningBasket = this.scanning_baskets.find(
          (c) => c.basket_code == this.basket_code
        );
        if (!checkScanningBasket) {
          const sBaskets = this.scanning_baskets.map((sb) => sb.basket_code);
          document.getElementById("error_sound_player").play();
          this.$vToastify.error(
            this.$t("messages.scan_basket_same_tracking", {
              codes: sBaskets.join(", "),
            })
          );
          this.basket_code = null;
          return false;
        }
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/get-basket-for-process-by-history",
          {
            basket_code: this.basket_code,
            id_warehouse: this.id_warehouse,
          }
        );
        this.scanned_baskets.push({ ...data.item });
        const scanning_baskets = [...data.items].filter(
          (i) => !this.scanned_baskets.find((j) => j.id === i.id)
        );
        this.scanning_baskets = [...scanning_baskets];
        this.isLoading = false;
        this.basket_code = null;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.basket_code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    async getWarehouses() {
      const { data } = await httpClient.post("/warehouse/v1/my-warehouse");
      this.warehouses = data.map((e) => ({
        value: e.id,
        text: e.code_name || "",
      }));
      if (this.warehouses.length === 1) {
        this.id_warehouse =
          (this.warehouses[0] && this.warehouses[0].value) || null;
        this.focusBasketInput();
      }
    },
    focusBasketInput() {
      this.waiting_baskets = [];
      if (!this.id_warehouse) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.basketCodeRef.focus();
        this.getReceiptBasketWaiting();
      });
    },
    async createCommand() {
      if (this.isDisabledBtn) {
        this.$vToastify.error(this.$t("messages.can_not_process_stowing"));
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/create-stowing-command",
          {
            scanned_baskets: this.scanned_baskets,
            id_warehouse: this.id_warehouse,
          }
        );
        this.$emit("submit", data);
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.basket_code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    async getReceiptBasketWaiting() {
      const { data } = await httpClient.post(
        "/goods-receipt/v1/list-waiting-stowing",
        {
          id_warehouse: this.id_warehouse,
        }
      );
      this.waiting_baskets = [...data];
      this.scanning_baskets = [];
      this.scanned_baskets = [];
    },
  },
  mounted() {
    // this.getWarehouses();
  },
};
</script>

<style scoped></style>
